import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';

import { Container, Row, Col, Image, Button } from 'react-bootstrap';

import { BodyCard, EngineCard } from 'components/Cards';
import RewindButton from 'components/RewindButton';
import LoadingSpinner from 'components/LoadingSpinner';
import { setBodyData, setBuilderData, setRawBuilderData } from 'store/entities/data';
import LocalizedLink from 'components/LocalizedLink';
import { TechDataModal } from 'components/modals';

import { setBody, setEngine } from 'store/entities/config';
import PlaceholderCar from 'assets/images/placeholder-car.png';

import './styles.scss';

const CarPartSelection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('equipment');
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { model, specification, body, gradeId, engine } = useSelector(state => state.config);
  const { builderData, bodyData } = useSelector(state => state.data);

  const { name, pictures, exteriors, equipment } = builderData;

  const { isFetching, refetch: fetchData } = useQuery(
    'builder-data',
    async () => {
      const [res1, res2] = await Promise.all([
        axios.get(`/builder/combined/${specification}`, {
          params: { language: `${i18n.language}_LV` },
        }),
        axios.get(`/builder/grade/${gradeId}`, {
          params: {
            'brand.name': 'Renault',
            'model.id': model,
            isActive: true,
            language: `${i18n.language}_LV`,
          },
        }),
      ]);
      return [res1, res2];
    },
    {
      enabled: false,
      onSuccess: res => {
        const builderData = res[0].data;
        dispatch(setBuilderData(builderData));
        dispatch(setBodyData(res[1].data));
      },
    }
  );

  const { refetch: fetchLatvianBuilderData } = useQuery(
    'builder-data-latvian',
    async () => {
      const res = await axios.get(`/builder/combined/${specification}`, {
        params: { language: `lv_LV` },
      });
      return res;
    },
    {
      enabled: false,
      onSuccess: res => {
        dispatch(setRawBuilderData(res.data));
      },
    }
  );

  useEffect(() => {
    if (model) {
      fetchData();
      fetchLatvianBuilderData();
    } else {
      window.location.replace('https://www.renaultgroup.com/');
    }
  }, [model, fetchData, fetchLatvianBuilderData]);

  useEffect(() => {
    if (!body && Object.keys(bodyData).length) {
      const key = Object.keys(bodyData)[0];
      dispatch(setBody(bodyData[key].id));
    }

    if (body && bodyData && !engine) {
      dispatch(setEngine(bodyData[body].engines[0].id || null));
    }
  }, [body, bodyData, dispatch, engine]);

  const handleModal = type => {
    setModalType(type);
    setIsModalOpen(true);
  };

  return (
    <>
      {isFetching && <LoadingSpinner />}
      <section>
        <Container className="mb-4 py-5">
          <Row>
            <Col xs="12" md="6" lg="4">
              <h2>{name}</h2>
              <RewindButton href={`/grade?id=${model}`} text={t('switch_vehicle')} />
            </Col>
            <Col xs="12" md="6" lg="4">
              <Image
                fluid
                src={`${pictures?.main || exteriors?.[0]?.pictures?.main || PlaceholderCar}`}
              />
            </Col>
            <Col xs="12" lg="4">
              <h5>{t('equipment')}</h5>
              <ul>
                {equipment?.slice(0, 4).map(item => (
                  <li key={item.id}>{item.name}</li>
                ))}
              </ul>
              <div className="d-grid gap-2">
                <Button variant="light" onClick={() => handleModal('equipment')}>
                  {t('view_more_btn')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-light">
        <Container className="py-5">
          {Object.keys(bodyData).length > 1 && (
            <>
              <h3 className="pb-3">{t('choose_body')}</h3>
              <Row xs={1} md={2} className="mb-5">
                {Object.keys(bodyData).map(key => (
                  <Col key={bodyData[key].id}>
                    <BodyCard bodyData={bodyData[key]} handleInfo={() => handleModal('techdata')} />
                  </Col>
                ))}
              </Row>
            </>
          )}
          <h3 className="pb-3">{t('choose_engine')} </h3>
          <Row xs={1} md={2} className="mb-5">
            {bodyData[body]?.engines
              .filter(
                (val, idx, self) => self.findIndex(foundVal => foundVal.id === val.id) === idx
              )
              .map((engine, idx) => (
                <Col key={`${engine.id}-${idx}`}>
                  <EngineCard engineData={engine} handleInfo={() => handleModal('techdata')} />
                </Col>
              ))}
          </Row>
          <Row>
            <Col xs={12} lg={{ offset: 8, span: 4 }}>
              <LocalizedLink to="/assemble">
                <Button className="w-100" onClick={() => window.scrollTo(0, 0)}>
                  {t('continue_button')}
                </Button>
              </LocalizedLink>
            </Col>
          </Row>
        </Container>
      </section>
      <TechDataModal show={isModalOpen} onClose={() => setIsModalOpen(false)} type={modalType} />
    </>
  );
};

export default CarPartSelection;
