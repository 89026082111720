import { Suspense } from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import store from 'store';
import LoadingSpinner from 'components/LoadingSpinner';
import Router from 'Router';

import i18n from './i18n.js';

const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1`;

window.i18n = i18n;

const App = () => {
  return (
    <Suspense fallback="">
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={<LoadingSpinner />} persistor={persistor}>
              <Router />
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </div>
    </Suspense>
  );
};

export default App;
