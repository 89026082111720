import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export const TechDataModal = ({ show, onClose, type = 'techdata' }) => {
  const { t } = useTranslation();
  const { builderData } = useSelector(state => state.data);

  const { techdata, equipment } = builderData;

  const mappedEquipmentData = useMemo(
    () =>
      equipment?.reduce((obj, el) => {
        obj[el.categoryName] = [...(obj[el.categoryName] || []), el];
        return obj;
      }, {}),
    [equipment]
  );

  return (
    <Modal show={show} onHide={onClose} size="lg" animation>
      <Modal.Header closeButton>
        <Modal.Title>{t(type === 'techdata' ? 'technical_spec' : 'equipment')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {type === 'techdata' ? (
            Object.keys(techdata || {}).map(key => (
              <Col xs={12} key={key}>
                <h5>{key}</h5>
                <ul className="techdata-list">
                  {techdata[key].map((item, idx) => (
                    <li key={`${item.name}-${idx}`}>
                      <span>{item.name}</span>
                      <span>{item.value}</span>
                    </li>
                  ))}
                </ul>
              </Col>
            ))
          ) : (
            <>
              <Col xs={12} lg={6}>
                {Object.keys(mappedEquipmentData || {}).map((key, idx) =>
                  idx % 2 === 0 ? (
                    <Col xs={12} key={key}>
                      <h5>{key}</h5>
                      <ul>
                        {mappedEquipmentData[key].map((item, idx) => (
                          <li key={`${item.name}-${idx}`}>{item.name}</li>
                        ))}
                      </ul>
                    </Col>
                  ) : null
                )}
              </Col>
              <Col xs={12} lg={6}>
                {Object.keys(mappedEquipmentData || {}).map((key, idx) =>
                  idx % 2 !== 0 ? (
                    <Col xs={12} key={key}>
                      <h5>{key}</h5>
                      <ul>
                        {mappedEquipmentData[key].map((item, idx) => (
                          <li key={`${item.name}-${idx}`}>{item.name}</li>
                        ))}
                      </ul>
                    </Col>
                  ) : null
                )}
              </Col>
            </>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
};
