import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Accordion, Container, Row, Col, Image, Card, ListGroup, Button } from 'react-bootstrap';

import useTotalPrice from 'hooks/useTotalPrice';
import { getExteriorPictures } from 'utils/dataUtils';
import { AccessoryListGroupItem, getSelectedAccessory } from 'utils/accessoryUtils';

import StickyAside from 'components/StickyAside';
import RewindButton from 'components/RewindButton';
import BottomBar from 'components/BottomBar';
import { OfferModal, PdfModal, SaveCarConfigModal } from 'components/modals';
import SelectedAccessories from './SelectedAccessories';
import SelectedPackages from './SelectedPackages';
import useSelectedAccessory from 'hooks/useSelectedAccessory';

import './styles.scss';

const Conclusion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const totalPrice = useTotalPrice();

  const { builderData } = useSelector(state => state.data);
  const { colorCode, interior } = useSelector(state => state.config);
  const { engine, body } = useSelectedAccessory();

  const { model: builderModel, price, exteriors, interiors, techdata } = builderData;

  const { main: mainImage } = getExteriorPictures(exteriors, colorCode);

  useEffect(() => {
    if (Object.keys(builderData).length === 0) {
      window.location.replace('https://www.renaultgroup.com/');
    }
  }, [builderData, navigate]);

  const getTechDataAmount = () => {
    let amount = 0;
    Object.keys(techdata || {}).forEach(item => (amount += techdata[item].length));
    return amount;
  };

  return (
    Object.keys(builderData).length !== 0 && (
      <section>
        <Container className="conclusion pt-5">
          <Row className="gx-lg-5">
            <Col xs="12" lg="8" className="pb-5">
              <h2>{builderModel?.name} </h2>
              <h5>{`${engine?.name}, ${body?.name}`} </h5>
              <Image className="px-5" fluid src={mainImage} />
              <Row>
                <Col className="pt-4">
                  <div className="d-flex justify-content-end mb-n4">
                    <RewindButton href="/assemble" text={t('changes_selections')} />
                  </div>
                  <h5 className="mt-3">
                    {t('your_configured_car', { model: `${builderModel?.name}` })}
                  </h5>
                  <ListGroup variant="flush" className="small">
                    <ListGroup.Item className="d-flex justify-content-between align-items-start">
                      {`${builderModel?.name} ${engine?.name} ${body?.name}`}
                      <span>{price}€</span>
                    </ListGroup.Item>
                  </ListGroup>
                  <h5 className="mt-3">{t('your_selected_accessories')}</h5>
                  <ListGroup variant="flush" className="small">
                    {getSelectedAccessory(exteriors, colorCode, AccessoryListGroupItem)}
                    {getSelectedAccessory(interiors, interior, AccessoryListGroupItem)}
                    <SelectedAccessories />
                    <SelectedPackages />
                  </ListGroup>
                  <Card bg="light" className="my-4">
                    <Card.Body className="py-4 px-4">
                      <Card.Text className="fs-2 py-2">{t('total')}</Card.Text>
                      <hr />
                      <Card.Text className="fs-2 py-2">
                        <strong>{totalPrice}€</strong>
                      </Card.Text>
                      <Card.Text className="consent">
                        <h5>{t('consent_short_title')}</h5>
                        <p>{t('consent_short_text')}</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Accordion>
                    <Accordion.Item eventKey={`accordionbtn-0`}>
                      <Accordion.Header className="fs-3">
                        {t('technical_spec') + ` (${getTechDataAmount()})`}
                      </Accordion.Header>
                      <Accordion.Body>
                        {Object.keys(techdata || {})?.map((category, idx) => {
                          const categoryData = techdata[category];

                          return (
                            <React.Fragment key={`subCategory-${idx}`}>
                              <p className="mb-0 fw-bold small">{category}</p>
                              <ListGroup variant="flush" className="small">
                                {categoryData.map((item, catIdx) => (
                                  <ListGroup.Item
                                    key={`speclist-${catIdx}`}
                                    className="d-flex justify-content-between align-items-start"
                                  >
                                    {item.name}
                                    <span>{item.value}</span>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </React.Fragment>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </Col>
            <Col xs="12" lg="4">
              <StickyAside />
            </Col>
          </Row>
        </Container>
        <BottomBar action={<Button>{t('save_button')}</Button>} />
        <SaveCarConfigModal />
        <OfferModal />
        <PdfModal />
      </section>
    )
  );
};

export default Conclusion;
