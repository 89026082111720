import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ListGroup } from 'react-bootstrap';

import useBreakPoints from 'hooks/useBreakPoints';
import { setModalState } from 'store/entities/app';
import { AccessoryListGroupItem, getSelectedAccessory } from 'utils/accessoryUtils';
import SelectedAccessories from 'components/StickyAside/SelectedAccessories';
import SelectedExtraPackages from 'components/StickyAside/SelectedExtraPackages';
import LocalizedLink from 'components/LocalizedLink';
import useTotalPrice from 'hooks/useTotalPrice';
import useSelectedAccessory from 'hooks/useSelectedAccessory';

import './styles.scss';

const StickyAside = ({ isAssembly, cartView }) => {
  const { t } = useTranslation();
  const { isSmallerThanLg } = useBreakPoints();
  const totalPrice = useTotalPrice();
  const { engine, body } = useSelectedAccessory();
  const dispatch = useDispatch();

  const { builderData } = useSelector(state => state.data);
  const { colorCode, interior, wheels } = useSelector(state => state.config);

  const { model: builderModel, price, exteriors, interiors, wheels: wheelsData } = builderData;

  if (isSmallerThanLg && !cartView) return null;

  const handleModal = (modal, newState, type) => {
    const payload = {
      modal,
      newState,
      type,
    };
    dispatch(setModalState(payload));
  };

  return (
    <>
      <div className="sticky-aside">
        {!cartView && (
          <div className="bg-white p-3 d-grid gap-2">
            <p className="mb-0 fw-bold">{t('your_car')}</p>
            <p>{totalPrice}€</p>
            {isAssembly ? (
              <LocalizedLink to="/conclusion">
                <Button variant="primary">{t('summary_button')}</Button>
              </LocalizedLink>
            ) : (
              <>
                <Button variant="primary" onClick={() => handleModal('offerModal', true, 'offer')}>
                  {t('ask_offer_button')}
                </Button>
                <Button
                  variant="light"
                  onClick={() => handleModal('offerModal', true, 'testdrive')}
                >
                  {t('book_test_button')}
                </Button>
                <Button variant="light" onClick={() => handleModal('pdfModal', true)}>
                  {t('pdf_button')}
                </Button>
              </>
            )}
          </div>
        )}
        {isAssembly && (
          <>
            <h4 className="mt-3">{builderModel?.name}</h4>
            <h5>{`${engine?.name}, ${body?.name}`}</h5>
            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex justify-content-between align-items-start bg-light">
                {t('price')}
                <span className="fw-bold ">{price} €</span>
              </ListGroup.Item>
              {getSelectedAccessory(exteriors, colorCode, AccessoryListGroupItem, {
                type: 'assembly',
              })}
              {getSelectedAccessory(wheelsData, wheels, AccessoryListGroupItem, {
                type: 'assembly',
              })}
              {getSelectedAccessory(interiors, interior, AccessoryListGroupItem, {
                type: 'assembly',
              })}
              <SelectedAccessories />
              <SelectedExtraPackages />
            </ListGroup>
            <div className="consent pt-4">
              <h5>{t('consent_short_title')}</h5>
              <p>{t('consent_short_text')}</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default StickyAside;
