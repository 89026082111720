import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Container, Row, Col } from 'react-bootstrap';

import Accessories from 'components/Accessories';
import RecommendedPackagesList from 'components/RecommendedPackagesList';
import AssemblySliderSelectors from 'components/AssemblySliderSelectors';
import ThreeSixtyWrapper from 'components/360Viewer/ThreeSixtyWrapper';
import StickyAside from 'components/StickyAside';
import RewindButton from 'components/RewindButton';
import BottomBar from 'components/BottomBar';
import { getActiveInteriorPicture } from 'utils/dataUtils';

import { ReactComponent as Fullscreen } from 'assets/images/icons/Fullscreen.svg';
import { ReactComponent as ExitFullscreen } from 'assets/images/icons/ExitFullscreen.svg';

import 'views/styles.scss';
import LocalizedLink from 'components/LocalizedLink';
import InteriorImage from 'views/Assemble/InteriorImage';
import useSelectedAccessory from 'hooks/useSelectedAccessory';

const Assemble = () => {
  const { t, i18n } = useTranslation();

  const { model, specification, interior } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { selectedTab } = useSelector(state => state.app);

  const { model: builderModel, price } = builderData;

  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const { engine, body } = useSelectedAccessory();

  if (!model && !specification) window.location.replace('https://www.renaultgroup.com/');

  return (
    model &&
    specification && (
      <>
        <section className="bg-light">
          <div className="bg-white">
            <Container className="pt-5">
              <Row
                className={`fullscreen-wrapper ${handle.active ? 'fullscreen-wrapper-active' : ''}`}
              >
                <FullScreen handle={handle}>
                  <Col xs md="3" className="hero-text top-0 start-0" style={{ zIndex: 1 }}>
                    <h2>{builderModel?.name}</h2>
                    <p>{`${engine?.name}, ${body?.name}`}</p>
                    <p className="mb-0">
                      <strong>{`${price}€`}</strong>
                    </p>
                    <RewindButton href={`/grade?id=${model}`} text={t('switch_vehicle')} />
                  </Col>
                  {selectedTab !== 2 && (
                    <Col sm="12" className="pt-5" style={{ zIndex: 0 }}>
                      <ThreeSixtyWrapper />
                      <Button
                        onClick={handle.exit}
                        variant="link"
                        className="btn-icon-right btn-fullscreen btn-fullscreen-exit"
                      >
                        {t('close_button')}
                        <ExitFullscreen />
                      </Button>
                      <Button
                        onClick={handle.enter}
                        variant="link"
                        className="btn-icon-right btn-fullscreen"
                      >
                        {t('full_view')}
                        <Fullscreen />
                      </Button>
                    </Col>
                  )}
                  {selectedTab === 2 && (
                    <InteriorImage
                      src={getActiveInteriorPicture(builderData.interiors, interior)}
                    />
                  )}
                </FullScreen>
              </Row>
            </Container>
          </div>
          <Container className="mb-4 pb-5">
            <Row>
              <Col>
                <AssemblySliderSelectors />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="bg-white">
          <Container>
            <Row className="position-relative gx-lg-5">
              <Col xs="12" lg="8">
                <h3>{t('recommended_packages')}</h3>
                <RecommendedPackagesList />
                <Accessories />
                <div className="my-5 text-end">
                  <LocalizedLink to="/conclusion">
                    <Button variant="primary">{t('summary_button')}</Button>
                  </LocalizedLink>
                </div>
              </Col>
              <Col xs="12" lg="4" className="position-relative">
                <StickyAside isAssembly />
              </Col>
            </Row>
          </Container>
        </section>
        <BottomBar
          action={
            <Button onClick={() => navigate(`${i18n.language}/conclusion`)}>
              {t('summary_button')}
            </Button>
          }
        />
      </>
    )
  );
};

export default Assemble;
